<!-- tsx -->
<template>
  <div class="transfer">
    <section class="toHeader">
      <div class="left">
        <span style="cursor: pointer;font-size:16px;margin-left:20px" @click="goBack()">
          <i class="el-icon-arrow-left" style="color:#000;font-size:16px"></i>划转
        </span> 
      </div>
    </section>

    <!-- 业务区 -->
    <section class="mainTrans">
      <div class="top wrapper">
        <p class="fangxiang">划转方向</p> 
        <div class="toFrom">
          <div class="leftTo">
            <span>从</span>
            <span>至</span>
          </div>
          <div class="centerFrom">
            <el-select v-model="from" placeholder="请选择账户" @change="change" style="width: 100%" class="itemsec">
              <el-option v-for="(item,index) in BaseMarkets" :key="index" :value="item.userAccountid" :label="item.accountName"></el-option>
            </el-select>
            <el-select v-model="to" placeholder="请选择账户" @change="changes" style="width: 100%"  class="itemsec">
              <el-option v-for="(item,index) in BaseMarkets" :key="index" :value="item.userAccountid" :label="item.accountName"></el-option>
            </el-select>
          </div>
        </div>
        <div class="money wrapper">
          <p class="fangxiang">划转金额</p> 
          <el-input v-model="number" type="number" placeholder="划转金额"></el-input>
          <p class="contentTit">账户资金 : {{ (selectMarket.balance - selectMarket.frozenFunds) | formatDigit}}</p>
          <p class="contentTit">账户冻结资金 : {{ selectMarket.frozenFunds | formatDigit}}</p>
          <el-button class="submit" :disabled="disable" @click="submit()">提交</el-button>
        </div>
      </div>
    </section>

    <section class="transferRep">
      <div class="transferRepTitle">
        <span style="color:#1c242c">划转记录(最近10条)</span>
        <!-- <span style="color:#2483ff;cursor: pointer;font-size:12px" @click="moreShow()">查看全部</span> -->
      </div>
      <el-table :data="tableData" style="width: 100%; min-height: 400px;">
        <template slot="empty">
          <div class="no-datas1">
            <div class="no-data-img" style="margin: 100px auto 0px; width: 120px; height: 100px;">
              <img src="../../../assets/images/no_data.png" alt="" style="width: 100%;">
            </div>
            <div class="no-data" style="margin-top: -25px !important; text-align: center;">{{ $t("message.No_data") }}</div>
          </div>
        </template>
        <el-table-column prop="occurTime" label="时间" width="180">
          <template slot-scope="scope">{{ scope.row.occurTime|dateFormate }}</template>
        </el-table-column>
        <el-table-column prop="bizName" label="类型" width="180"></el-table-column>
        <el-table-column prop="amount" label="金额" width="180"></el-table-column>
        <el-table-column prop="marketName" label="市场" width="180"></el-table-column>
        <el-table-column prop="note" label="流水号"></el-table-column>
      </el-table>
    </section>

  </div>
</template>

<script >
export default {
components: {},
  data() {
    return {
      BaseMarkets: [],
      tableData: [],
      from: null,
      to: null,
      selectMarket: {
        'accountName': '',
        'balance': 0.00,
        'frozenFunds': 0.00,
        'userAccountid': 0,
        'userId': 0
      },
      asd: null,
      number: '',
      disable: false
    }
  },
filters: {
  formatDigit(e) {
    if (e) {
      return Number(e).toFixed(2)
    } else {
      return Number(0).toFixed(2)
    }
  }
},
created() {},
mounted() {
  this.param_findAccountByUserId()
  this.param_queryMyIntegralDetail()
},
computed: {},
watch: {},
methods: {
  goBack(){
    this.$router.push('/conditionOfAssets')
  },
  // 查看更多
  moreShow(){
    this.$router.push('/financialRecord')
  },
  // 资金划转市场列表
   param_findAccountByUserId() {
     http.postFront(protocol.param_findAccountByUserId).then(response => {
      if (response.data) {
        if (response.data && response.data.code == '0') {
          this.BaseMarkets = response.data.value
          if (this.asd != null) {
            for (let i = 0; i < this.BaseMarkets.length; i++) {
              const element = this.BaseMarkets[i]
              if (element.userAccountid === this.asd) {
                this.selectMarket = element
              }
            }
          }
        }
      }
    })
   },
   // 查询划转记录
   param_queryMyIntegralDetail() {
    // // console.log(this.selectData)
    let protocolUrlData = {}
    // if (this.selectData) {
    //   // 历史
    //   protocolUrlData = protocolFwd.param_queryMyIntegralDetailHis
    //   protocolUrlData.param.starTime = this.selectData[0]
    //   protocolUrlData.param.endTime = this.selectData[1]
    // } else {
    //   // 当前
    protocolUrlData = protocolFwd.param_queryMyIntegralDetail
    // }
    // 其它
    protocolUrlData.param.typeCode = null
    protocolUrlData.param.page = 0
    protocolUrlData.param.size = 10
    http.postFront(protocolUrlData).then(response => {
      if (response.data) {
        if (response.data && response.data.code == '0') {
          const arr = response.data.value.content
          this.tableData = arr
        }
      }
    })
  },
   // 资金来源
   change(selOption) {
     this.asd = selOption
     console.log(selOption, 999)
     this.selectMarket = {
       'accountName': '',
       'balance': 0.00,
       'frozenFunds': 0.00,
       'userAccountid': 0,
       'userId': 0
     }
     for (let i = 0; i < this.BaseMarkets.length; i++) {
       const element = this.BaseMarkets[i]
       if (element.userAccountid === selOption) {
         this.selectMarket = element
       }
     }
   },
   // 资金去向
   changes(selOption) {
     console.log(selOption)
   },
   submit() {
     if (!this.number) {
       this.$EL_MESSAGE('划转资金未填写')
       return
     }
     if (!this.from) {
       console.log(this.from, 'from')
       this.$EL_MESSAGE('请选择划转账户')
       return
     }
     if (!this.to) {
       this.$EL_MESSAGE('请选择划转账户')
       return
     }
     if (Number(this.number) <= 0) {
       this.$EL_MESSAGE('划转资金填写错误')
       return
     }
     if (Number(this.number) > (Number(this.selectMarket.balance) - Number(this.selectMarket.frozenFunds))) {
       this.$EL_MESSAGE('划转资金超额')
       return
     }
     if (this.from === this.to) {
       this.$EL_MESSAGE('两次选择账户相同')
       return
     }
     this.param_transferFundsOwn()
   },
    // 资金划转(多市场间划转)
    param_transferFundsOwn() {
      var that = this
      this.disable = true
      protocol.param_transferFundsOwn.param.amount = this.number
      protocol.param_transferFundsOwn.param.fromUserAccountId = this.from
      protocol.param_transferFundsOwn.param.toUserAccountId = this.to
      http.postFront(protocol.param_transferFundsOwn).then(response => {
        const { code, message, value } = response.data
        this.$EL_MESSAGE(message)
        if (Number(code) === 0) {
          this.disable = false
          this.param_findAccountByUserId()
          this.param_queryMyIntegralDetail();
          // this.from = null
          // this.to = null
          // this.selectMarket = {
          //   'accountName': '',
          //   'balance': 0.00,
          //   'frozenFunds': 0.00,
          //   'userAccountid': 0,
          //   'userId': 0
          // }
          
        }else{
          this.disable = false
          this.$EL_MESSAGE(message)
        }
      })
    }
},
}
</script>

<style lang='scss' scoped>
// @import url(); 引入公共css类
.transfer{
  width: 100%;
  padding-top: 20px;
  background-color: $baseHeaderBGColor;
}
.wrapper{
  width: 720px;
  margin: 0 auto;
}
.toHeader{
  height: 60px;
  line-height: 60px;
  width: 1000px;
  margin: 0 auto;
  // padding: 0 45px;
  background-color: #fff;
  .left{
    font-size: 16px;
    color: #000;
  }
}
.top{
  .toFrom{
    border: 1px solid #dfe2e7;
    border-radius: 2px;
    background: #fff;
    height: 114px;
    display: flex;

    .leftTo{
      width: 50px;
      display: flex;
      flex-direction: column;
      margin: 25px 0 0 30px;
      span{
        margin-bottom: 25px;
      }
    }
    .centerFrom{
      width: 80%;
      .itemsec{
        margin-top: 12px;
      }
     
    }

  }
}
.money{
  margin-top: 30px;
  .contentTit{
    line-height: 18px;
    font-size: 12px;
    margin-top: 8px;
    color: #9aa5b5;
  }
  .submit{
    background-color: #2d8cf0;
    color: #FFF;
    width: 200px;
    margin-top: 36px;
    height: 40px;
    border-radius: 2px;
    font-size: 16px;
  }
}
.mainTrans{
  margin: 20px auto;
  width: 1000px;
  border: 1px solid #e6ecf2;
  background-color: #fff;
  border-radius: 2px;
  min-height: 440px;
  color: #1c242c;
  padding: 32px 0;
}
.transferRep{
  margin: 30px auto;
  width: 1000px;
  border: 1px solid #e6ecf2;
  background-color: rgba(242,246,250,.5);
  border-radius: 2px;
  min-height: 440px;
  color: #1c242c;
  .transferRepTitle{
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    line-height: 40px;
    background: #f2f6fa;
  }

}
.fangxiang{
  color: #495666;
  font-size: 12px;
  margin-bottom: 8px;
}

</style>
